import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comments"
export default class extends Controller {
  static targets = ["container", "form"]
  static values = { url: String }
  page = 1
  connect() {
    this.scrollToBottom();
    this.initSummernote();
    document.addEventListener('turbo:submit-end', (e) => {
      if (e.detail.success && e.detail.formSubmission.submitter.formMethod != 'get') {
        this.resetForm();
        setTimeout(() => {
          this.scrollToBottom();
        }, 100);
      }
    });
  }

  initSummernote() {
    $(this.formTarget).find('.summernote').summernote({
      height: 50,
      airMode: true,
      popover: {
        air: [
          ['style', ['bold', 'italic', 'underline', 'clear']],
        ]
      }
    });
  }

  resetForm() {
    this.formTarget.reset();
    $(this.formTarget).find('.summernote').summernote('reset');
  }

  scrollToBottom() {
    this.containerTarget.scrollTop = this.containerTarget.scrollHeight;
  }
}
