import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="igps"
export default class extends Controller {
  static targets = ["map", "list", "form"];
  map = null;
  locations = [];
  routers = [];

  connect() {
    this.renderMap();
    this.search();
  }

  search(event) {
    if (event)
      event.preventDefault();
    
    const url = this.formTarget.action + "?" + new URLSearchParams(new FormData(this.formTarget)).toString();
    history.pushState(null, "", url.replace(".json", ""));
    fetch(url, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      }
    })
      .then(response => response.json())
      .then(json => {
        console.log(json);
        this.routers = json;
        this.routers = this.routers.map(router => {
          router.color = "#" + Math.floor(Math.random()*16777215).toString(16);
          router.visible = true;
          return router;
        });
        this.displayRouters();
        this.updateRouterList();
        this.panToCenter();
      })
  }


  renderMap() {
    if (this.map == null) {
      var latlng = new google.maps.LatLng(51.3908788,6.2088703);
      this.map = new google.maps.Map(this.mapTarget,
        {
          zoom: 15,
          center: latlng,
          mapId: 'DEMO_MAP_ID'
        }
      )
    } else {
      console.log("Map already rendered")
    }
  }

  panToCenter() {
    var bounds = new google.maps.LatLngBounds();
    this.routers.forEach(router => {
      router.locations.forEach(location => {
        bounds.extend(new google.maps.LatLng(location.lat, location.lng));
      });
    });
    this.map.fitBounds(bounds);
  }

  panToRouter(router) {
    var bounds = new google.maps.LatLngBounds();

    router.locations.forEach(location => {
      bounds.extend(new google.maps.LatLng(location.lat, location.lng));
    });

    this.map.fitBounds(bounds);
  }

  displayRouters() {
    this.routers.forEach(router => {
      if (!router.visible) {
        return;
      }

      router.locations.forEach(location => {
        var latlng = new google.maps.LatLng(location.lat, location.lng);

        var circle = new google.maps.Circle({
          strokeColor: router.color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: router.color,
          fillOpacity: 0.35,
          map: this.map,
          center: latlng,
          radius: parseFloat(location.accuracy)
        })

        var marker = new google.maps.Marker({
          position: latlng,
          map: this.map,
          title: router.mac + " - " + location.accuracy + "m",
          icon: app.icons["none"],
          zIndex: 1
        });

        var infowindow = new google.maps.InfoWindow({
          content: `<div>${router.mac} - ${router.name} (${parseFloat(location.accuracy).toFixed(3)} m.)</div>`
        });

        marker.addListener("click", () => {
          this.closeAllInfoWindows();
          infowindow.open(this.map, marker);
        });

        location.circle = circle;
        location.marker = marker;
        location.infowindow = infowindow;
      });
    });
  }

  closeAllInfoWindows() {
    this.routers.forEach(router => {
      router.locations.forEach(location => {
        location.infowindow.close();
      });
    });
  }

  updateRouterList() {
    this.listTarget.innerHTML = "";
    this.routers.forEach(router => {
      var li = document.createElement("li");
      var checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.checked = router.visible;
      checkbox.addEventListener("change", () => {
        router.visible = checkbox.checked;
        router.locations.forEach(location => {
          location.circle.setVisible(router.visible);
          location.marker.setVisible(router.visible);
          location.infowindow.close();
        });
      });
      
      li.style.listStyle = "none";
      li.style.padding = "5px";
      li.style.cursor = "pointer";
      li.addEventListener("click", () => {
        this.panToRouter(router);
      });

      
      li.innerHTML = `${router.mac} - ${router.name} (${router.locations.length}) - avg acc: ${this.calcAvgAccuracy(router.locations)}, dist: ${router.avg_distance_between_locations} m.`;
      li.appendChild(checkbox);
      li.style.color = router.color;
      this.listTarget.appendChild(li);
    });
  }

  calcAvgAccuracy(locations) {
    var sum = 0;
    locations.forEach(location => {
      sum += parseFloat(location.accuracy);
    });
    return (sum / locations.length).toFixed(3);
  }

  removeAllRouters() {
    this.routers.forEach(locations => {
      locations.forEach(location => {
        location.circle.setMap(null);
        location.marker.setMap(null);
        location.infowindow.close();
      });
    });
  }
}