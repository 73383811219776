import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fences"
export default class extends Controller {
  static targets = [ "list", "map", "new_form", "edit_form", "new_button"]
  static values = {selected: Object, deviceValue: Object }
  map = null;
  editMode = false;
  editingFence = null;
  editingFenceShape = null;
  fences = [];
  selected = null;

  connect() {
    this.renderMap();
    this.renderLoading();
    this.fetchFences();    
    this.selected = this.selectedValue;
  }

  reload() {
    this.editMode = false
    if (this.editingFenceShape) {
      this.editingFenceShape.setMap(null);
      this.editingFenceShape = null;
      this.editingFence = null;
    }
    this.renderLoading();
    this.fetchFences();
  }

  fetchFences() {
    fetch(window.location.pathname + '.json')
      .then(response => response.json())
      .then(data => {
        if (this.fences.length > 0) {
          this.fences.forEach(fence => {
            fence.shape.setMap(null);
          });
        }
        this.fences = data.fences;
        this.deviceValue = data.device;
        if (this.selected.id == null && this.fences.length > 0) {
          this.selected = this.fences[0];
        }
        this.render();
      });
  }

  updateFenceForm() {
    this.dispatch('update', { detail: { fence: this.editingFence } });
  }

  renderLoading() {
    this.listTarget.innerHTML = `<div class="fw-anim-container"><div class="anim"><span></span><span></span> <span></span></div></div>`;
    this.listTarget.classList.add('loading');
    let overlay = document.createElement('div');
    overlay.classList.add('fw-anim-container');
    overlay.classList.add('overlay');
    overlay.innerHTML = `<div class="anim"><span></span><span></span> <span></span></div>`;
    this.mapTarget.before(overlay);
  }

  renderLoaded() {
    if (this.listTarget.classList.contains('loading')) {  
      this.listTarget.classList.remove('loading');
      this.listTarget.innerHTML = '';
      this.mapTarget.previousElementSibling.remove();
    }
  }

  edit({ detail: data }) {
    this.editMode = data.editMode;
    this.editingFence = data.fence;

    if (this.editingFenceShape) {
      this.editingFenceShape.setMap(null);
    }
    if (this.editMode && this.editingFence != null) {
      if (this.editingFence.fence_type == 'CIRCLE') {
        this.editingFence = { ...this.editingFence
          , latitude: this.editingFence.latitude || this.map.center.lat()
          , longitude: this.editingFence.longitude || this.map.center.lng()
          , radius: this.editingFence.radius || 200
          , fence_points: null
        };
      } else {
        this.editingFence = { ...this.editingFence
          , radius: null
          , fence_points: this.editingFence.fence_points || this.defaultPolygon(this.editingFence.latitude, this.editingFence.longitude)
        };
      }
      
      this.fences.filter(fence => fence.id == this.editingFence.id).forEach(fence => {
        fence.shape.setMap(null);
      });

      this.editingFenceShape = this.createFence(this.editingFence, { editable: true, draggable: true });
      this.updateFenceForm();
      
      this.attachListeners();
    }

    if (this.editMode) {
      this.new_buttonTarget.classList.add('d-none');
    } else {
      this.new_buttonTarget.classList.remove('d-none');
    }
  }

  createPosition(fence) {
    return new google.maps.LatLng(fence.latitude, fence.longitude);
  }

  createShape(fence, { editable = false, draggable = false } = {}) {
    var radius = fence.radius;
    var color = this.colorFor(fence, {editable: editable});
    // backgroundColor = colorFor(fence, {editable: editable});
    switch(fence.fence_type) {
      case 'CIRCLE':
        return new google.maps.Circle({
          center: this.createPosition(fence),
          radius: radius,
          map: this.map,
          editable: editable,
          draggable: draggable,
          strokeColor: color,
          fillColor: color,
        });
      case 'POLYGON':
        return new google.maps.Polygon({
          paths: fence.fence_points,
          map: this.map,
          editable: editable,
          draggable: draggable,
          strokeColor: color,
          fillColor: color,
        });
      }
  }

  colorFor(fence, { editable = false } = {}) {
    switch(fence.alarm_type) {
      case 'ENTER_EXIT':
        return editable ? '#097EB9' : '#1A8FCA';
      case 'ENTER':
        return editable ? '#169d50' : '#27ae60';
      default:
        return editable ? '#b0281a' : '#c0392b';
    }
  }

  createFence(fence, { editable = false, draggable = false } = {}) {
    if (fence.fence_points != null && !Array.isArray(fence.fence_points)) {
      fence.fence_points = fence.fence_points.split(";").filter(e => e != "").map(point => {
        var [lat, lng] = point.split(",");
        return { lat: parseFloat(lat), lng: parseFloat(lng) };
      });
    }
    var shape = this.createShape(fence, { editable: editable, draggable: draggable });
    shape.addListener('click', () => {
      if (!this.editMode) {
        this.selected = fence;
        this.render();
      }
    });
    return shape;
  }  

  render() {
    this.renderLoaded();
    this.renderDevice();
    this.renderMap();

    this.listTarget.innerHTML = this.fences.map(fence => this.createFenceListItem(fence)).join('');

    if (this.shapes != undefined) {
      this.shapes.forEach(shape => {
        shape.setMap(null);
      });
    }

    var fences = this.fences.map(fence => {
      if (fence.shape != null) {
        fence.shape.setMap(null);
        fence.shape = null;
      }
      fence.shape = this.createFence(fence);
      
      return fence;
    });
    this.fences = fences;
  }

  renderMap() {
    if (this.map == null) {
      var latlng = new google.maps.LatLng(52.1326, 5.2913);
      this.map = new google.maps.Map(this.mapTarget, 
        {
          center: latlng,
          zoom: 15,
          mapId: 'DEMO_MAP_ID'
        }
      )
    } else {
      if (this.fences.length > 0 && this.selected != null) {
        this.map.panTo(new google.maps.LatLng(this.selected.latitude, this.selected.longitude));
      } else if (this.deviceValue != null && this.deviceValue.latitude != null && this.deviceValue.longitude != null) {
        this.map.panTo(new google.maps.LatLng(this.deviceValue.latitude, this.deviceValue.longitude));
      }
    }
  }

  renderDevice() {
    var device = this.deviceValue;

    var position = new google.maps.LatLng(device.latitude, device.longitude);

    var markerImage = document.createElement('img');
    markerImage.src = device.icon;

    var marker = new google.maps.marker.AdvancedMarkerElement({
      position: position,
      map: this.map,
      title: device.name,
      content: markerImage
    });
  }

  defaultPolygon(lat, lng) {
    if (lat == null) {
      lat = this.map.center.lat();
    }

    if (lng == null) {
      lng = this.map.center.lng();
    }
    lat = parseFloat(lat);
    lng = parseFloat(lng);

    var squarePoints = [
      { lat: lat + 0.002, lng: lng + 0.005 },
      { lat: lat - 0.002, lng: lng + 0.005 },
      { lat: lat - 0.002, lng: lng - 0.005 },
      { lat: lat + 0.002, lng: lng - 0.005 }
    ];
    return squarePoints;
  }

  select(event) {
    event.preventDefault();
    this.editMode = false
    if (this.editingFenceShape) {
      this.editingFenceShape.setMap(null);
      this.editingFenceShape = null;
      this.editingFence = null;
      this.dispatch('cancel-form');
    }

    this.selected = this.fences.find(fence => fence.id == event.currentTarget.dataset.fenceId);
    this.render();
  }

  createFenceListItem(fence) {
    var active = this.selected?.id == fence.id && !this.editMode ? 'active-menu-item m-menu__item--active m-menu__item--open' : '';

    if (fence.fence_type == 'CIRCLE') {
      var circle_or_polygon_ref = `<small><i class="fa fa-bullseye"></i> ${fence.radius}m</small>`;
    } else {
      var circle_or_polygon_ref = `<i class="fa fa-map-marker"></i>`;
    }

    return `
      <li class="m-menu__item ${active}" data-fence-id="${fence.id}" aria-haspopup="true">
        <a class="m-menu__link" href="#" data-action="click->fences#select" data-fence-id="${fence.id}">
          ${this.iconForAlarmType(fence)}

          <span class="m-menu__link-title">
            <span class="m-menu__link-wrap">
              <span class="m-menu__link-text">
                
                ${fence.name}
                ${circle_or_polygon_ref}
                <br>
                <small>${fence.center_address}</small>
              </span>
            </span>
          </span>
          
        </a>

        <turbo-frame id="edit_device_fence_${fence.id}">
          ${this.editButtons(fence)}
        </turbo-frame>
      </li>
    `
  }

  editButtons(fence) {
    if (this.editMode) {
      return '';
    } else {
      return `<div class="m-menu__submenu">
                <ul class="m-menu__subnav">
                  <li class="m-menu__item" data-fence-id="${fence.id}">
                    <a class="m-menu__link" href="${fence.edit_path}" data-turbo-frame="edit_device_fence_${fence.id}">
                      <span class="m-menu__link-text">Edit</span>
                    </a>
                  </li>
                  <li class="m-menu__item" data-fence-id="${fence.id}">
                    <a class="m-menu__link" href="${fence.copy_path}" data-turbo-frame="resource_modal" target="resource_modal">
                      <span class="m-menu__link-text">Copy</span>
                    </a>
                  </li>
                  <li class="m-menu__item" data-fence-id="${fence.id}">
                    <a class="m-menu__link" href="#" data-action="click->fences#delete" data-fence-id="${fence.id}">
                      <span class="m-menu__link-text">Delete</span>
                    </a>
                  </li>
                </ul>
              </div>`;
    }
  }

  iconForAlarmType(fence) {
    switch(fence.alarm_type) {
      case 'ENTER_EXIT':
        return `<i class="m-menu__link-icon fa fa-arrows pr--color-in-out" style="width: 30px; padding-right: 10px;"></i>`
      case  'ENTER':
        return `<i class="m-menu__link-icon fa fa-sign-in pr--color-in" style="width: 30px; padding-right: 10px;"></i>`
      default:
        return `<i class="m-menu__link-icon fa fa-sign-out pr--color-out" style="width: 30px; padding-right: 10px;"></i>`
    }
  }

  attachListeners() {
    if (this.editingFence.fence_type == 'CIRCLE') {
      this.editingFenceShape.addListener('center_changed', () => {
        this.editingFence.latitude = this.editingFenceShape.getCenter().lat();
        this.editingFence.longitude = this.editingFenceShape.getCenter().lng();
        this.updateFenceForm();
      });
      this.editingFenceShape.addListener('radius_changed', () => {
        this.editingFence.radius = this.editingFenceShape.getRadius();
        this.updateFenceForm();
      });
    } else if (this.editingFence.fence_type == 'POLYGON') { 
      this.editingFenceShape.getPath().addListener('insert_at', this.updateAfterPolygonEvent.bind(this));
      this.editingFenceShape.getPath().addListener('set_at', this.updateAfterPolygonEvent.bind(this));
      this.editingFenceShape.getPath().addListener('remove_at', this.updateAfterPolygonEvent.bind(this));
      this.editingFenceShape.addListener('dragend', this.updateAfterPolygonEvent.bind(this));
    }
  }

  updateAfterPolygonEvent() {
    this.editingFence.fence_points = this.editingFenceShape.getPath().getArray().map(point => {
      return { lat: point.lat(), lng: point.lng() };
    });

    var latlng = this.centerOfPolygon(this.editingFence.fence_points);
    this.editingFence.latitude = latlng.lat;
    this.editingFence.longitude = latlng.lng;
    this.updateFenceForm();
  }

  centerOfPolygon(points) {
    var lat = 0;
    var lng = 0;
    points.forEach(point => {
      lat += parseFloat(point.lat);
      lng += parseFloat(point.lng);
    });

    return { lat: lat / points.length, lng: lng / points.length };
  }

  delete(event) {
    event.preventDefault();
    const fenceId = event.currentTarget.dataset.fenceId;
    fetch(`${window.location.href.replace("#", "")}/${fenceId}.json`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })
      .then(data => {
        this.reload();
      });
  }
}
