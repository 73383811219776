import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fences-copy"
export default class extends Controller {
  static targets = ["form"];
  static values = { url: String };

  connect() {
    this.dispatch('edit', { detail: { editMode: true, fence: null } });

    $('#geofence_device_id').select2({
      ajax: {
        url: this.urlValue,
        dataType: 'json',
        data: function(params) {
          return {
            search: params.term,
            page: params.page
          };
        },
        processResults: function (data) {
          var transformed = data.map(function(device) {
            var text = `${device.serial_number} - (${device.name})`;
            return { id: device.serial_number, text: text };
          });
          return {
            results: transformed
          };
        }
      }
    });

    document.addEventListener('turbo:submit-end', (e) => {
      console.log("oiiii")
      if (e.detail.fetchResponse.response.status == 200) {
        this.dispatch('edit', { detail: { editMode: false } });
        this.dispatch('render', { detail: { fences: undefined } });
      } else {
        this.dispatch('edit', { detail: { editMode: true, fence: null} });
      }
    });
  }

  disconnect() {
    this.dispatch('edit', { detail: { editMode: false, fence: undefined } }); 
  }

  cancel() {
    this.dispatch('edit', { detail: { editMode: false, fence: null } });
  }
}
